export function galery() {
    
    const numberSLides = document.querySelectorAll(".galery-single .carousel-item"),
            slider = document.querySelector('div[data-wrapper-slide] .carousel-inner'),
            currentSlider = document.querySelector('.galery-single__counter-curent'),
            totalNumSlider = document.querySelector('.galery-single__counter-total');

    function setZero(num) {
        if (num < 10) {
            return `0${num}`
        }
        else{
            return num
        }      
        
    }

        if (currentSlider && totalNumSlider) {
            function setCurrent(elem) {
                // console.log("ForEach");
                elem.forEach( (item, index) => {
                    if (item.classList.contains('active')) {
                        console.log("ForEach");
                        let newCurrent = index + 1  ;
                        currentSlider.innerText = setZero(newCurrent) ;
                    }
                });
            }

            setCurrent(numberSLides);
            totalNumSlider.innerText = setZero(numberSLides.length);
            const observer = new MutationObserver(()=> {
                        setCurrent(numberSLides);
            });

            observer.observe(slider,  {
                childList: true,
                attributes: true,
                subtree: true,
            });

        }
            
            
}

// Uncaught TypeError: не удалось выполнить «наблюдать» на «Мутации ButationObserver»: Параметр 1 не имеет в типе узла типа