export function scrollTopMenu() {
    const arrTop = document.querySelector('.to-top'),
          header = document.querySelector('header .header-container'),
          searchTop = document.querySelector('.right-nav__search .search-top');
    let  scrollPrev = 0;
    window.addEventListener('scroll', () => {
        let scrolled = window.pageYOffset;
        // console.log(scrolled);
      if (window.pageYOffset  < document.documentElement.clientHeight) {
        arrTop.classList.add('hidden');
        
      }else{
        arrTop.classList.remove('hidden');
      }

      if (scrolled > document.documentElement.clientHeight && scrolled > scrollPrev) {

        header.style.transition = '0.5s';
        header.style.transform = 'translateY(-200%)';
        searchTop.classList.add('hidden-search');
    
      }else{
        header.style.transform = '';
    }
    scrollPrev = scrolled;
    });

    arrTop.addEventListener('click', () => {
        // window.scrollTo(window.pageXOffset, 0);
        let step = 1;
        function timeOuter() {

            if (window.pageYOffset > 0) {
                requestAnimationFrame(timeOuter);
                window.scrollTo(window.pageXOffset, window.pageYOffset-step);
                step += 1;
            } else {
               
                window.scrollTo(0, 0);
                step = 1;
            }
        }
        timeOuter();
    });
}

