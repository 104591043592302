export function validForm() {
    const inputFlie = document.getElementById('cart-file-pay');
    if (inputFlie) {
       inputFlie.addEventListener('input', () => {
        let fileName = inputFlie.files[0].name;
        let labelText = inputFlie.closest('label').querySelector('.feeds__label-text');
        let oldText = labelText.innerHTML
        labelText.classList.add('text-normal');
        labelText.innerText = fileName;

    }); 
    }
    
}