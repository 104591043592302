export function hiddeSearch() {
   
      
        document.addEventListener('click', (e) => {
            let searchForm = document.querySelector('.right-nav__search .search-top');
            if (e.target.dataset.search === 'search-icon') {
        
            //    searchForm.classList.toggle('hidden-search');
                    if (searchForm.classList.contains('tabindex-none')) {
                        searchForm.classList.remove('tabindex-none');
                        setTimeout(() => {
                            searchForm.classList.remove('hidden-search');
                        }, 10);
                        
                    }else{

                        searchForm.classList.add('hidden-search');
                        setTimeout(() => {
                            searchForm.classList.add('tabindex-none');
                        }, 250);
                        
                    }
                    
                   
                
            } else if (e.target.dataset.search !== "search-form") {
                    searchForm.classList.add('hidden-search');
                    setTimeout(() => {
                        setTimeout(() => {
                            searchForm.classList.add('tabindex-none');
                        }, 350);
                        
                    }, 10);
                }

            

        });

}