// import './styles.css'
import './index.html'
import './single.html'
import './cart.html'
import './catalog.html'
import './distributors.html'
import './promotion.html'
import './promotion-disabled.html'
import './promotion-empty.html'
import './promotion-single.html'
import './blog.html'
import './blog-empty.html'
import './blog-single.html'
import './galery.html'
import './galery-single.html'
import './search.html'
import './404.html'
import './video.html'
import './video-single.html'
import './LIST-PAGES.html'
import './map.html'
import './stylus/style.styl'
import Swiper from 'swiper/bundle';
import 'swiper/css';
// import 'swiper/css/navigation';
import 'swiper/css/pagination';
import * as vlfIsWebp from "./modules/is_webp.js";
import * as scroolTop from "./modules/scroll-top_menu.js";
import * as isMobile from "./modules/is_mobile.js";
import * as search from "./modules/hidde_search.js";
import * as navMenu from "./modules/nav_menu.js";
import * as validcart from "./modules/form_cart.js";
import * as galery from "./modules/galery-single.js";
import * as braerMap from "./modules/yandex-map.js";



addEventListener('DOMContentLoaded', () => {
  scroolTop.scrollTopMenu();
  vlfIsWebp.isWebp();
  search.hiddeSearch();
  isMobile.isMobTouchScreen();
  navMenu.navMenu();
  validcart.validForm();
  galery.galery();
  // braerMap.braerMap();
  const swiper = new Swiper('.swiper', {
    // Optional parameters
    // direction: 'vertical',
    loop: true,
    slidesPerView: 3,
    // If we need pagination
    // pagination: {
    //   el: '.swiper-pagination',
    // },

    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    // **************************
    breakpoints: {
      300: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    }
    //********************** */


  });

  function init() {
    let map = new ymaps.Map('braerMap', {
        center: [92.961560450343335,65.93405370162426],
        // center: [52.961560450343335,35.93405370162426],
        zoom: 10
    });
}
yamps.ready(init);

});