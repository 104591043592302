export function navMenu() {
    const body = document.querySelector('body'),
          nav =  document.querySelector('nav'),
          navlist = nav.querySelector('.nav-bar__list'),
          navBtn = nav.querySelector('.burger-btn'),
          closeBtn = navBtn.querySelector('.burger-btn__line'),
          subMenuLists = document.querySelectorAll('nav.nav-bar .sub-menu');
          
    if (body.classList.contains('_touch') && subMenuLists) {
        subMenuLists.forEach(item => {
            if (item.closest('.nav-bar__item')) {
                let parentLink = item.closest('.nav-bar__item');
                let siblingLink = item.closest('.nav-bar__item').querySelector('.nav-bar__link');
                siblingLink.insertAdjacentHTML('afterend', '<span class="icon-arrow-bottom"></span>');
                parentLink.style.paddingRight = '5px';
                item.classList.add('vlf-dropdown--border-top');
            }
        });
        
    }

    if (navBtn || body.classList.contains('_touch')) {
        document.addEventListener('click', (e) => {
            let target = e.target;

            if (target.hasAttribute('data-menubtn')) {
               
                
                body.classList.toggle('_lock');
                if (body.classList.contains('_lock')) {
                    navlist.classList.add('nav-bar__list--background');
                    setTimeout(() => {
                        navlist.classList.remove('nav-bar__list--hidde');
                    }, 65);
                }else{
                    navlist.classList.add('nav-bar__list--hidde');
                    setTimeout(() => {
                        navlist.classList.remove('nav-bar__list--background');
                    }, 65);
                }
                
               
                closeBtn.classList.toggle('burger-btn__line--close');
                navBtn.classList.toggle('burger-btn--close');
                
            }
            if (target.classList.contains('icon-arrow-bottom') && target.closest('.nav-bar__item')  ) {
                target.closest('.nav-bar__item').classList.toggle('active');
                let subList = target.closest('.nav-bar__item').querySelector('.sub-menu');
                if (target.closest('.nav-bar__item').classList.contains('active')) {

                    subList.classList.remove('tabindex-none')
                    subList.classList.remove('hidden-dropdown')

                }else{
                    subList.classList.add('tabindex-none')
                    subList.classList.add('hidden-dropdown')
                    
                    // setTimeout(() => {
                    //     subList.classList.add('hidden-dropdown')
                    // }, 10);
                    
                }

                
            }else if(!(target.classList.contains('icon-arrow-bottom'))){

            }
        });
    }
 
}